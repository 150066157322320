import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';

export const StyledDivModalTop = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledDivModalMid = styled.div`
  display: flex;
  justify-content: center;
  background-color: #ebeeee;
  width: 100%;
  max-height: 80%;
  border-top: 1px solid rgb(54 57 59);
  border-bottom: 1px solid rgb(54 57 59);
`;

export const StyledDivModalBot = styled.div`
  height: 100%;
`;

export const StyledDivModalWrapper = styled.div`
  background: #0f0f0f;
  max-height: 90%;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 50%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-height: 90%; /* Set a maximum height for the modal content container */
  overflow-y: auto; /* Allow scrolling within the modal content container */
  @media only screen and (max-width: 900px) {
    min-width: 70%;
  }
  @media only screen and (max-width: 769px) {
    min-width: 80%;
  }
  @media only screen and (max-width: 550px) {
    min-width: 90%;
  }
`;

export const StyledTechStach = styled(Typography)`
  display: block;
  margin-left: 8px;
`;

export const StyledModalImg = styled.img`
  width: 100%;
`;

export const StyledDetailLinksDiv = styled.div`
  margin-left: 8px;
  display: inline-block;
  @media only screen and (max-width: 900px) {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
`;

export const StyledTryButton = styled(Button)`
  transform: translateY(-9px);
  margin-right: 16px;
  margin-top: 14px;
  transform: translateY(-14px);
  @media only screen and (max-width: 900px) {
    height: 35px;
  }
`;

export const StyledGithubImg = styled.a`
  font-size: 35px;
  margin-left: 6px;
  margin-right: 16px;
  @media only screen and (max-width: 900px) {
    margin-left: 12px;
    font-size: 45px;
  }
`;

export const StyledModalTitleTypography = styled(Typography)`
  margin-bottom: 5px;
  color: #c5c7c7;
`;
