import React, { Component } from 'react';
import { ScrollFadeWrapper } from './helpers/ScrollFadeWrapper';

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const profilepic = 'images/' + this.props.data.image;
    const bio = this.props.data.bio;
    const bioMiddle = this.props.data.bioMiddle;
    const bioEnding = this.props.data.bioEnding;
    return (
      <ScrollFadeWrapper>
        <section id="about">
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt="Rodrigo Profile Pic"
              />
            </div>
            <div className="nine columns main-col">
              <h2>About Me</h2>

              <p>{bio}</p>
              <p>{bioMiddle}</p>
              <p>
                {bioEnding}{' '}
                <a
                  href="#home"
                  style={{
                    color: '#8AB4F7',
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                  }}
                >
                  above.
                </a>
              </p>
              {/* <div className="row">
                <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
                    <span>{name}</span>
                    <br />
                    <span>
                      {street}
                      <br />
                      {city} {state}
                    </span>
                    <br />
                    <span>{phone}</span>
                    <br />
                    <span>{email}</span>
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </ScrollFadeWrapper>
    );
  }
}

export default About;
