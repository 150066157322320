import styled from '@emotion/styled';

export const CardOverlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
    0deg,
    rgb(34, 35, 38) 9%,
    rgba(33, 34, 37, 0.5) 42%,
    rgba(34, 35, 38, 0) 156%
  );
  height: 100%;
  width: 100%;
  h3,
  p {
    color: #ffffff;
    margin-left: 20px;
  }

  p {
    font-weight: bold;
  }

  pre {
    font-family: 'opensans-bold', sans-serif;
    font-size: 10px;
    color: #58a795;
    margin: 0;
    margin: 0 0 10px 20px;
    font-weight: bold;
  }
  :hover {
    opacity: 0.6;
  }
`;

export const ProjectCardContainer = styled.div`
  border-radius: 6px;
  background: ${({ projectObject }) => `url('${projectObject.cardPic}')`};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  cursor: pointer;
  margin: 10px 20px 20px 20px;
  min-width: 350px;
  max-height: 450px;
  @media only screen and (max-width: 450px) {
    min-width: 300px;
  }
  @media only screen and (max-width: 320px) {
    min-width: 260px;
  }
  height: 500px;
  overflow: hidden;
  :hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
    background: ${({ projectObject }) =>
      `url('${
        projectObject.gifUrlCropped
          ? projectObject.gifUrlCropped
          : projectObject.cardPic
      }')`};
    background-size: cover;
  }
};`;

export const FilterDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 20px;
`;

export const FilterButton = styled.button`
  padding: 0 5px;
  font-size: 12px;
  border-radius: 12px;
  background-color: #3d695fdb;
`;
