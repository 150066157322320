import { ModalWrapper } from './organisms/ModalWrapper';
import React, { Component } from 'react';
import {
  ProjectCardContainer,
  CardOverlay,
  FilterDiv,
  FilterButton,
} from './styles/Portfolio.styles';
import { ScrollSlideWrapper } from './helpers/ScrollSlideWrapper';

export default class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      particularProject: null,
      screenWidth: window.screen.width,
      ascendingOrder: false,
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWidth);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }

  updateWidth = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  handleClick = (project) => {
    return this.setState({
      isOpen: true,
      particularProject: project,
    });
  };

  handleClose = () => {
    return this.setState({
      isOpen: false,
    });
  };

  toggleSortOrder = () => {
    this.setState((prevState) => ({
      ascendingOrder: !prevState.ascendingOrder,
    }));
  };

  render() {
    if (!this.props.data) return null;

    // Sort projects based on ascending/descending order
    const sortedProjects = this.props.data.projects.slice().sort((a, b) => {
      const aYear = parseInt(a.time);
      const bYear = parseInt(b.time);
      return this.state.ascendingOrder ? aYear - bYear : bYear - aYear;
    });

    let portfolioWrapperStyle = {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: '100%',
    };

    if (this.state.screenWidth < 813) {
      portfolioWrapperStyle = {
        ...portfolioWrapperStyle,
        justifyContent: 'center',
      };
    }

    // mapped Projects function
    const projects = sortedProjects.map((project, i) => {
      return (
        <React.Fragment key={project.cardTitle + i}>
          <div style={portfolioWrapperStyle}>
            <ScrollSlideWrapper direction={'right'}>
              <ProjectCardContainer
                projectObject={project}
                onClick={() => this.handleClick(project)}
              >
                <CardOverlay>
                  <h3>{project.cardTitle}</h3>
                  {project.deployedLink && (
                    <pre style={{ color: 'white' }}>Demo available</pre>
                  )}
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <pre>{project.stack}</pre>
                    <pre
                      style={{
                        color: 'white',
                        fontSize: 'bold',
                        marginRight: '20px',
                      }}
                    >
                      {project.time}
                    </pre>
                  </div>
                </CardOverlay>
              </ProjectCardContainer>
            </ScrollSlideWrapper>
          </div>
          <ModalWrapper
            isOpen={this.state.isOpen}
            handleClose={this.handleClose}
            screenWidth={this.state.screenWidth}
            projectData={this.state.particularProject}
          />
        </React.Fragment>
      );
    });

    // MAIN PORTFOLIO RETURN
    return (
      <section id="portfolio">
        <div
          className="row"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <h1>Check Out Some of My Projects</h1>
          <FilterDiv>
            <FilterButton onClick={this.toggleSortOrder}>
              Order by Date{' '}
              {this.state.ascendingOrder ? (
                <span>&uarr;</span>
              ) : (
                <span>&darr;</span>
              )}
            </FilterButton>
          </FilterDiv>
          <div style={portfolioWrapperStyle}>{projects}</div>
        </div>
      </section>
    );
  }
}
