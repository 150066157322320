import { Typography, Modal, Fade } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  StyledDetailLinksDiv,
  StyledDivModalBot,
  StyledDivModalMid,
  StyledDivModalTop,
  StyledDivModalWrapper,
  StyledGithubImg,
  StyledModalImg,
  StyledModalTitleTypography,
  StyledTechStach,
  StyledTryButton,
} from '../styles/ModalWrapper.styles';

export const ModalWrapper = ({
  isOpen,
  screenWidth,
  handleClose,
  projectData,
}) => {
  let modalDescription = projectData?.modalDescription;
  let stack = projectData?.stack;
  if (screenWidth < 1025) {
    modalDescription = projectData?.modalDescription;
    stack = projectData?.stack;
  }
  if (screenWidth < 769) {
    modalDescription = projectData?.modalDescriptionSmall;
    stack = projectData?.stackSmall;
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      keepMounted
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { opacity: 0.8 },
      }}
    >
      <Fade in={isOpen} timeout={300}>
        <StyledDivModalWrapper>
          <StyledDivModalTop>
            <IconButton onClick={handleClose}>
              <CloseIcon fontSize="large" sx={{ color: 'white' }} />
            </IconButton>
          </StyledDivModalTop>
          <StyledDivModalMid>
            <StyledModalImg
              src={
                projectData?.gifUrl ? projectData.gifUrl : projectData?.cardPic
              }
            />
          </StyledDivModalMid>
          <StyledDivModalBot>
            <div>
              <div style={{ margin: '15px 10px 5px 10px' }}>
                <StyledModalTitleTypography variant="h3">
                  {projectData?.cardTitle}
                </StyledModalTitleTypography>
                <Typography
                  variant="body5"
                  id="transition-modal-description"
                  sx={{ color: '#c5c7c7' }}
                >
                  {modalDescription}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '5px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <StyledTechStach variant="body4">{stack}</StyledTechStach>
                </div>
                <StyledDetailLinksDiv>
                  {projectData?.githubUrl && (
                    <StyledGithubImg href={projectData.githubUrl}>
                      <div className={'fa fa-github'} />
                    </StyledGithubImg>
                  )}
                  {projectData?.deployedLink && (
                    <StyledTryButton
                      onClick={() =>
                        window.open(projectData?.deployedLink, '_blank')
                      }
                      variant="text"
                      sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '10px',
                      }}
                    >
                      Demo
                    </StyledTryButton>
                  )}
                </StyledDetailLinksDiv>
              </div>
            </div>
          </StyledDivModalBot>
        </StyledDivModalWrapper>
      </Fade>
    </Modal>
  );
};
