import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
import Portfolio from './Components/Portfolio';
// import Testimonials from './Components/Testimonials';
import { BrowserRouter } from 'react-router-dom';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLinkIndex: 0,
      resumeData: {},
    };

    ReactGA.initialize('UA-110570651-1');
    ReactGA.pageview(window.location.pathname);
  }

  getResumeData() {
    fetch('./resumeData.json')
      .then((response) => response.json())
      .then((data) => {
        this.setState({ resumeData: data });
      })
      .catch((error) => {
        console.error(error);
        alert(error.message);
      });
  }

  setParentActiveLinkState = (index) => {
    this.setState({ activeLinkIndex: index });
  };

  componentDidMount() {
    this.getResumeData();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  handleScroll = () => {
    const sections = [
      'header', // Adjust this to match the actual IDs of your sections
      'about',
      'resume',
      'portfolio',
      'testimonials',
      'footer',
    ];
    let activeIndex = 0;

    for (let i = sections.length - 1; i >= 0; i--) {
      const section = document.getElementById(sections[i]);
      if (section) {
        const { top } = section.getBoundingClientRect();
        if (top <= 100) {
          // Adjust this value for the desired sensitivity
          activeIndex = i;
          break;
        }
      }
    }

    this.setState({ activeLinkIndex: activeIndex });
  };

  render() {
    const { resumeData, activeLinkIndex } = this.state;

    if (!resumeData) {
      return <div className="App" />;
    }

    return (
      <BrowserRouter>
        <div className="App">
          <Header
            data={resumeData.main}
            activeLinkIndex={activeLinkIndex}
            setParentActiveLinkState={this.setParentActiveLinkState}
          />
          <About data={resumeData.main} />
          <Resume data={resumeData.resume} />
          <Portfolio data={resumeData.portfolio} />
          {/* <Testimonials data={resumeData.testimonials} /> */}
          <Footer data={resumeData.main} />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
