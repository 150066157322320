import React, { Component } from 'react';
import { ScrollFadeWrapper } from './helpers/ScrollFadeWrapper';
import BIRDS from 'vanta/dist/vanta.birds.min';

class Header extends Component {
  constructor(props) {
    super();
    this.vantaRef = React.createRef();
    this.vantaEffect = null; // Initialize vantaEffect to null!
  }

  componentDidMount() {
    this.initializeVanta();
  }

  componentDidUpdate(prevProps) {
    // Check if the data prop has changed, indicating an update
    if (prevProps.data !== this.props.data) {
      this.destroyVanta(); // Destroy the previous Vanta.js animation
      this.initializeVanta(); // Initialize a new Vanta.js animation
    }
  }

  componentWillUnmount() {
    this.destroyVanta();
  }

  initializeVanta() {
    if (this.vantaRef.current) {
      this.vantaEffect = BIRDS({
        el: this.vantaRef.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.0,
        minWidth: 200.0,
        scale: 1.0,
        scaleMobile: 1.0,
        backgroundColor: '#0F0F0F',
        color1: 0x2e3c32,
        color2: 0x17503d,
        wingSpan: 25.0,
        quantity: 3.0,
        backgroundAlpha: 0.98,
      });
    }
  }

  destroyVanta() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy();
      this.vantaEffect = null;
    }
  }

  render() {
    if (!this.props.data) return null;

    const { activeLinkIndex, setParentActiveLinkState } = this.props;

    const name = this.props.data.name;
    const description = this.props.data.description;
    const resumeDownload = this.props.data.resumedownload;

    return (
      <header
        id="home"
        ref={this.vantaRef}
        style={{ background: 'linear-gradient(#474f47, #262626)' }}
      >
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className={activeLinkIndex === 0 ? 'current' : ''}>
              <a href="#home" onClick={() => setParentActiveLinkState(0)}>
                Home
              </a>
            </li>

            <li className={activeLinkIndex === 1 ? 'current' : ''}>
              <a href="#about" onClick={() => setParentActiveLinkState(1)}>
                About
              </a>
            </li>

            <li className={activeLinkIndex === 2 ? 'current' : ''}>
              <a href="#resume" onClick={() => setParentActiveLinkState(2)}>
                Resume
              </a>
            </li>

            <li className={activeLinkIndex === 3 ? 'current' : ''}>
              <a href="#portfolio" onClick={() => setParentActiveLinkState(3)}>
                Projects
              </a>
            </li>
            {/* <li className={activeLinkIndex === 4 ? 'current' : ''}>
              <a
                href="#testimonials"
                onClick={() => setParentActiveLinkState(4)}
              >
                References
              </a>
            </li> */}
          </ul>
        </nav>
        <div className="row banner">
          <div className="banner-text">
            <ScrollFadeWrapper direction={'bottom'}>
              <h1 className="responsive-headline">{name}</h1>
            </ScrollFadeWrapper>
            <ScrollFadeWrapper direction={'bottom'} timeoutDuration={1200}>
              <h3>
                {description}
                <a
                  href="#portfolio"
                  style={{
                    color: '#8AB4F7',
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                  }}
                >
                  portfolio section below.
                </a>
              </h3>
            </ScrollFadeWrapper>
            <hr />
            <ScrollFadeWrapper direction={'bottom'} timeoutDuration={1400}>
              <div className="columns download resume-div-wrapper">
                <a href={resumeDownload} className="button resume-btn">
                  <i className="fa fa-download" /> PDF Resume
                </a>
              </div>
            </ScrollFadeWrapper>
          </div>
        </div>

        <p className="scrolldown" style={{ transform: 'translateX(-12px)' }}>
          <a href="#about">
            <i className="icon-down-circle" />
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
