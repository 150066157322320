import React, { Component } from 'react';
import { ScrollFadeWrapper } from './helpers/ScrollFadeWrapper';

class Footer extends Component {
  render() {
    if (!this.props.data) return null;

    const networks = this.props.data.social.map(function (network) {
      return (
        <li key={network.name}>
          <a target="_blank" rel="noopener noreferrer" href={network.url}>
            <i className={network.className} />
          </a>
        </li>
      );
    });

    return (
      <footer>
        <div
          style={{
            width: '80%',
            height: '.5px',
            backgroundColor: '#525252',
            margin: '20px auto',
          }}
        />
        <div className="row">
          <ScrollFadeWrapper>
            <div className="twelve columns">
              <ul className="social-links">{networks}</ul>
            </div>
          </ScrollFadeWrapper>
          <div id="go-top">
            <a title="Back to Top" href="#home">
              <i className="icon-up-open" />
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
