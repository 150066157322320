import React, { Component } from 'react';
import LinkIcon from '@mui/icons-material/Link';
import { ScrollSlideWrapper } from './helpers/ScrollSlideWrapper';

class Resume extends Component {
  skillColors = ['#FF5733', '#33FF6B', '#3384FF', '#FF33E9', '#33FFEC'];

  render() {
    if (!this.props.data) return null;

    // const skillmessage = this.props.data.skillmessage;
    const education = this.props.data.education.map(function (education) {
      const projectImage = education.logo;
      return (
        <div key={education.school}>
          <div style={{ display: 'flex' }}>
            <img className="logos" alt="logos" src={projectImage} width={40} />
            <a
              href={education.website}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'flex' }}
            >
              <h3>{education.school}</h3>
              <LinkIcon
                fontSize="large"
                sx={{
                  color: '#8AB4F7',
                  transform: 'translateY(6px)',
                  marginLeft: '5px',
                }}
              />
            </a>
          </div>
          <p className="info">
            {education.degree} <span>&bull;</span>
            <em className="date">{education.graduated}</em>
          </p>
          <p>{education.description}</p>
        </div>
      );
    });

    const work = this.props.data.work.map(function (work) {
      const projectImage = work.logo;
      return (
        <div key={work.company}>
          <div style={{ display: 'flex' }}>
            <img className="logos" alt="logos" src={projectImage} width={40} />
            <a
              href={work.website}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'flex' }}
            >
              <h3>{work.company}</h3>
              <LinkIcon
                fontSize="large"
                sx={{
                  color: '#8AB4F7',
                  transform: 'translateY(6px)',
                  marginLeft: '5px',
                }}
              />
            </a>
          </div>
          <p className="info">
            {work.title}
            <span>&bull;</span> <em className="date">{work.years}</em>
          </p>
          <p>{work.description}</p>
        </div>
      );
    });

    // const skills = this.props.data.skills.map((skills, index) => {
    //   const backgroundColor = this.skillColors[index % this.skillColors.length];
    //   const className = 'bar-expand ' + skills.name.toLowerCase();
    //   const width = skills.level;

    //   return (
    //     <li key={skills.name}>
    //       <span style={{ width, backgroundColor }} className={className} />
    //       <em>{skills.name}</em>
    //     </li>
    //   );
    // });

    return (
      <section id="resume">
        <ScrollSlideWrapper>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Work</span>
              </h1>
            </div>
            <div className="nine columns main-col">{work}</div>
          </div>
        </ScrollSlideWrapper>
        <ScrollSlideWrapper>
          <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>Education</span>
              </h1>
            </div>
            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">{education}</div>
              </div>
            </div>
          </div>
        </ScrollSlideWrapper>
        {/* <ScrollSlideWrapper>
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Skills</span>
              </h1>
            </div>
            <div className="nine columns main-col">
              <p>{skillmessage}</p>
              <div className="bars">
                <ul className="skills">{skills}</ul>
              </div>
            </div>
          </div>
        </ScrollSlideWrapper> */}
      </section>
    );
  }
}

export default Resume;
