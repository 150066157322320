import React from 'react';
import { Slide } from '@mui/material';
import { useInView } from 'react-intersection-observer';

export const ScrollSlideWrapper = ({
  children,
  timeoutDuration = 1000,
  direction = 'right',
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div ref={ref}>
      <Slide
        disableripple="true"
        in={inView}
        timeout={timeoutDuration}
        direction={direction}
      >
        {children}
      </Slide>
    </div>
  );
};
